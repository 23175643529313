import { CdkVirtualScrollViewport, ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule, formatDate } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import EnumConstant from 'src/app/models/enums/enumConstant';
import { INotification } from 'src/app/models/notification';
import { IUser } from 'src/app/models/user';
import { NotificationDataSourceService } from 'src/app/services/notification-datasource.service';
import { NotificationService } from 'src/app/services/notification.service';
import { TimeAgoPipe } from 'src/app/shares/date/time-ago.pipe';
import { EmptyComponent } from 'src/app/shares/empty/components/empty/empty.component';

@Component({
  selector: 'app-notification',
  standalone: true,
  imports: [
    CommonModule,
    ScrollingModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatListModule,
    MatBadgeModule,
    TimeAgoPipe,
    EmptyComponent,
    MatProgressSpinnerModule,
    TranslateModule
  ],
  templateUrl: './notification.component.html',
  styleUrl: './notification.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationComponent implements OnChanges, OnInit, OnDestroy {
  private readonly notificationService = inject(NotificationService);
  private readonly router = inject(Router);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);

  @ViewChild(CdkVirtualScrollViewport)
  cdkVirtualScrollViewport!: CdkVirtualScrollViewport;
  courseStatus = EnumConstant;
  notifications = [];
  notificationDetail: any;
  dataSource!: NotificationDataSourceService;
  @Input() routeId!: number;
  @ViewChild('buttonTrigger') button!: MatMenuTrigger;
  user: IUser = JSON.parse(localStorage.getItem('account')!);
  subscription = new Subscription();

  ngOnInit(): void {
    this.dataSource = new NotificationDataSourceService(this.notificationService);
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if (changes['routeId']) {
      NotificationDataSourceService.isOpen = false;
      this.dataSource = new NotificationDataSourceService(this.notificationService);
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  menuOpened() {
    NotificationDataSourceService.isOpen = true;
    this.dataSource = new NotificationDataSourceService(this.notificationService);
    //used to prevent when remove the menu from the dom and lost data
    this.cdkVirtualScrollViewport.setRenderedContentOffset(0);
    //set  10 items to be rendered
    this.cdkVirtualScrollViewport.setRenderedRange({ start: 0, end: 9 });
  }

  goToNotificationSource(data: INotification) {
    const timeout = setTimeout(() => {
      let date: string | null = data.application_requests?.deliver_date
        ? formatDate(data.application_requests?.deliver_date, 'dd-MM-yyyy', 'en-Us')
        : null;

      if (data.seen_date && data?.application_requests) {
        this.router.navigate([`/request/all//${data.application_requests._id}/detail/info`], {
          queryParams: {
            status: data.application_requests.status,
            deliver_date: date
          }
        });
      } else {
        this.notificationService
          .seenOne(data._id!)
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe(res => {
            this.dataSource = new NotificationDataSourceService(this.notificationService);
            if (data?.application_requests)
              this.router.navigate([`/request/all//${data.application_requests._id}/detail/info`], {
                queryParams: {
                  status: data.application_requests.status,
                  deliver_date: date
                }
              });
          });
      }

      clearTimeout(timeout);
    }, 200);
  }

  markAllAsRead() {
    this.notificationService
      .markAllSeen()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => {
          this.dataSource = new NotificationDataSourceService(this.notificationService);
        }
      });
  }
}
