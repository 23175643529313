<button mat-icon-button class="button-border alert" [matMenuTriggerFor]="notification"
  #notificationTrigger="matMenuTrigger" (menuOpened)="menuOpened()">
  <mat-icon [matBadge]="dataSource.unreadCount > 0 ? dataSource.unreadCount : ''" aria-hidden="false"
    matBadgeSize="small" svgIcon="notification"></mat-icon>
</button>

<mat-menu #notification="matMenu" xPosition="before" backdropClass="notification">
  <mat-nav-list (click)="$event.stopPropagation();">
    <div class="flex items-center justify-content-between">
      <span class="title">{{ 'notification' | translate }}</span>

      @if(dataSource.cachedNotifications.length > 0) {
      <button mat-button color="primary" (click)="markAllAsRead()">
        <mat-icon>done_all</mat-icon>
        <span>{{'button.seen_all' | translate}}</span>
      </button>
      }

    </div>

    <cdk-virtual-scroll-viewport itemSize="75" class="notification-box" [minBufferPx]="800" [maxBufferPx]="900"
      [ngClass]="dataSource.cachedNotifications.length < 1 ? 'no-data' : ''">
      <mat-list-item *cdkVirtualFor="let data of dataSource; index as i" class="noti-text">
        <div class="flex items-center justify-content-between"
          (click)="notificationTrigger.closeMenu() ;goToNotificationSource(data)">
          <section [ngClass]="{'seen': data?.seen_date}">
            <span>{{data.name}}</span>
            <br>
            <small class="time-ago">{{data.createdAt | timeAgo}}</small>
          </section>

          <span [ngClass]="{'unread-circle': !data?.seen_date}"></span>
        </div>
      </mat-list-item>

      @if(dataSource.isLoading) {
      <div class="spinner">
        <mat-spinner strokeWidth="4" [diameter]="50"></mat-spinner>
      </div>
      }
      @else if(dataSource.cachedNotifications.length < 1 && !dataSource.isLoading) { <div class="empty">
        <app-empty [width]="150" [height]="80"></app-empty>
        </div>
        }
    </cdk-virtual-scroll-viewport>
  </mat-nav-list>
</mat-menu>
