import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IUser } from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class UserDataService {
  private UserDataSubject = new BehaviorSubject<IUser | null>(null);
  public currentUserData = this.UserDataSubject.asObservable();

  constructor() {}
  changeUserData(userData: any): void {
    this.UserDataSubject.next(userData);
  }
}
