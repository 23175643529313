import { formatDate } from '@angular/common';
import { inject, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'timeAgo',
  standalone: true
})
export class TimeAgoPipe implements PipeTransform {
  private readonly translate = inject(TranslateService);

  transform(date: string): string {
    if (!date) {
      return this.translate.instant('a_long_time_ago');
    }
    let time = (Date.now() - Date.parse(date)) / 1000;
    if (time < 10) {
      return this.translate.instant('just_now');
    } else if (time < 60) {
      return this.translate.instant('a_moment_ago');
    }
    const divider = [60, 60, 24, 30, 12];
    const string = ['second', 'minute', 'hour', 'day', 'month', 'year'];

    let i: number;
    for (i = 0; Math.floor(time / divider[i]) > 0; i++) {
      time /= divider[i];
    }
    const plural = Math.floor(time) > 1 ? 's' : '';

    return (Math.floor(time) > 7 && string[i] === 'day') || string[i] === 'month' || string[i] === 'year'
      ? formatDate(new Date(date), 'dd/MM/yyyy HH:mma', 'en-US')
      : Math.floor(time) + ' ' + this.translate.instant('date.' + string[i] + plural) + this.translate.instant('date.ago');
  }
}
